import axios from 'axios';

const state = {
    chat_gpt: "",
};

const getters = {

};

const actions = {
    chatgpt_create({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.post('chat-gpt', data)
            .then(response => {
                commit("PUSH_CHATGPT", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    chatgpt_update({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.put('chat-gpt/' + data.id, data)
            .then(response => {
                commit("PUSH_CHATGPT", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    chatgpt_list({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.get('chat-gpt', { params: data })
            .then(response => {
                commit("PUSH_CHATGPT", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    chatgpt_dataset_list({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.get('chat-gpt/' + data.chatgpt_id + '/datasets', { params: data })
            .then(response => {
                commit("PUSH_CHATGPT", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    chatgpt_history_list({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.get('chat-gpt/' + data.chatgpt_id + '/history', { params: data })
            .then(response => {
                commit("PUSH_CHATGPT", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    chatgpt_dataset_create({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.post('chat-gpt/' + data.id + '/datasets', data)
            .then(response => {
                commit("PUSH_CHATGPT", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    chatgpt_dataset_get({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.get('chat-gpt/' + data.id + '/datasets/' + data.dataset_id)
            .then(response => {
                commit("PUSH_CHATGPT", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    chatgpt_dataset_update({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.put('chat-gpt/' + data.id + '/datasets/' + data.dataset_id, data)
            .then(response => {
                commit("PUSH_CHATGPT", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    chatgpt_delete_dataset({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.delete('chat-gpt/' + data.id + '/datasets/' + data.dataset_id)
            .then(response => {
                commit("PUSH_CHATGPT", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    chatgpt_delete_history({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.delete('chat-gpt/' + data.id + '/history/' + data.history_id)
            .then(response => {
                commit("PUSH_CHATGPT", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    chatgpt_train({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.post('chat-gpt/' + data.id + '/train')
            .then(response => {
                commit("PUSH_CHATGPT", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    chatgpt_delete({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.delete('chat-gpt/' + data.id)
            .then(response => {
                commit("PUSH_CHATGPT", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    chatgpt_detail({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.get('chat-gpt/' + data.id)
            .then(response => {
                commit("PUSH_CHATGPT", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
}

const mutations = {
    PUSH_CHATGPT(state, data){
        state.chat_gpt = data
    },
};


export default {
  state,
  getters,
  actions,
  mutations
};
