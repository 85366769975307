<template>
    <aside v-if="asideShow" class="sidebar">
        <div class="sidebar__menu-group">
            <ul class="sidebar_nav">
                <div v-if="level == 'user' || level == 'agency' || level == 'user-agency' || level == 'admin'">
                    <li class="menu-title">
                        <span>Main menu </span>
                    </li>
                    <li>
                        <router-link to="/dashboard">
                            <span class="nav-icon fas fa-home"></span>
                            <span class="menu-text">Dashboard</span>
                        </router-link>
                    </li>
                    <li>
                        <a href="https://tutorial.starsender.id/docs/" target="_blank">
                            <span class="nav-icon fas fa-book-reader"></span>
                            <span class="menu-text">Tutorial</span>
                        </a>
                    </li>
                    <!-- <li>
                        <a href="" class="">
                            <span class="nav-icon fas fa-headphones"></span>
                            <span class="menu-text">Dashboard CS</span>
                        </a>
                    </li> -->
                    <li class="menu-title">
                        <span>Data </span>
                    </li>
                    <li>
                        <router-link to="/contact">
                            <span class="nav-icon fas fa-address-book"></span>
                            <span class="menu-text">Data Kontak</span>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/group">
                            <span class="nav-icon fas fa-users"></span>
                            <span class="menu-text">Group Kontak</span>
                        </router-link>
                    </li>
                    <li class="menu-title">
                        <span>Outgoing </span>
                    </li>
                    <li>
                        <router-link to="/send">
                            <span class="nav-icon fas fa-paper-plane"></span>
                            <span class="menu-text">Kirim Pesan</span>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/outgoing">
                            <span class="nav-icon fas fa-envelope"></span>
                            <span class="menu-text">List Pesan</span>
                        </router-link>
                    </li>
                    <!-- <li>
                        <router-link to="/broadcast">
                            <span class="nav-icon fas fa-bullhorn"></span>
                            <span class="menu-text">List Broadcast</span>
                        </router-link>
                    </li> -->
                    <li class="menu-title">
                        <span>Incoming </span>
                    </li>
                    <li>
                        <router-link to="/incoming">
                            <span class="nav-icon fas fa-inbox"></span>
                            <span class="menu-text">List Pesan</span>
                        </router-link>
                    </li>
                    <li class="menu-title">
                        <span>Otomasi </span>
                    </li>
                    <li>
                        <router-link to="/autoreply">
                            <span class="nav-icon fas fa-reply-all"></span>
                            <span class="menu-text">Autoreply</span>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/google-form-generator">
                            <span class="nav-icon fas fa-file-code"></span>
                            <span class="menu-text">Google Form</span>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/google-spreadsheet-generator">
                            <span class="nav-icon fas fa-file-code"></span>
                            <span class="menu-text">Google Spreadsheet</span>
                        </router-link>
                    </li>
                    <li class="menu-title">
                        <span>Fitur Premium</span>
                    </li>
                    <li>
                        <router-link to="/web-whatsapp">
                            <span class="nav-icon fas fa-comment"></span>
                            <span class="menu-text">Web Whatsapp</span>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/customer-service">
                            <span class="nav-icon fas fa-headphones-alt"></span>
                            <span class="menu-text">Customer Service</span>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/chat-widget">
                            <span class="nav-icon fas fa-address-card"></span>
                            <span class="menu-text">Chat Widget</span>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/chat-gpt">
                            <span class="nav-icon fas fa-robot"></span>
                            <span class="menu-text">ChatGPT</span>
                            <span class="badge badge-pill badge-success text-white" style="padding-left: 5px !important;padding-right: 5px !important;padding-top:3px !important;margin-left:5px;color:white !important">New</span>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/campaign">
                            <span class="nav-icon fas fa-newspaper"></span>
                            <span class="menu-text">Campaign</span>
                        </router-link>
                    </li>
                    <!--<li>
                        <router-link to="/order-online">
                            <span class="nav-icon fas fa-shopping-cart"></span>
                            <span class="menu-text">OrderOnline.id</span>
                        </router-link>
                    </li>-->
                    <li>
                        <router-link to="/link-rotator">
                            <span class="nav-icon fas fa-sync"></span>
                            <span class="menu-text">Product Link Rotator</span>
                        </router-link>
                    </li>
                    <!-- <li>
                <a href=""
                class="">
                <span class="nav-icon fas fa-shopping-bag"></span>
                <span class="menu-text">Form Order</span>
                </a>
            </li> -->
                    <li class="menu-title">
                        <span>Integrasi </span>
                    </li>
                    <!-- <li>
                        <a href="" class="">
                            <span class="nav-icon fas fa-book-reader"></span>
                            <span class="menu-text">Tutorial</span>
                        </a>
                    </li> -->
                    <!-- <li>
                        <a href="" class="">
                            <span class="nav-icon fas fa-code-branch"></span>
                            <span class="menu-text">Integrasi</span>
                        </a>
                    </li> -->
                    <li>
                        <a href="https://docs.starsender.online" target="_blank">
                            <span class="nav-icon fas fa-code"></span>
                            <span class="menu-text">Dokumentasi Rest API</span>
                        </a>
                    </li>
                    <li>
                        <router-link to="/webhook">
                            <span class="nav-icon fas fa-file-import"></span>
                            <span class="menu-text">Webhook</span>
                        </router-link>
                    </li>

 
                    <li class="menu-title">
                        <span>Pengaturan </span>
                    </li>
                    <li>
                        <router-link to="/device">
                            <span class="nav-icon fas fa-mobile-alt"></span>
                            <span class="menu-text">Device</span>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/billing">
                            <span class="nav-icon fas fa-wallet"></span>
                            <span class="menu-text">Tagihan</span>
                        </router-link>
                    </li>

                    <div v-if="level == 'agency'">
                        <li class="menu-title">
                            <span> Agency Menu </span>
                        </li>
                        <li>
                            <router-link to="/agency/users">
                                <span class="nav-icon fas fa-users-cog"></span>
                                <span class="menu-text">User Agency</span>
                            </router-link>
                        </li>
                    </div>
                </div>

                <div v-if="level == 'customer_service'">
                    <li class="menu-title">
                        <span>Main menu </span>
                    </li>
                    <li>
                        <router-link to="/cs-page/dashboard">
                            <span class="nav-icon fas fa-home"></span>
                            <span class="menu-text">Dashboard</span>
                        </router-link>
                    </li>
                    <li class="menu-title">
                        <span>Fitur Premium</span>
                    </li>
                    <li>
                        <router-link to="/cs-page/web-whatsapp">
                            <span class="nav-icon fas fa-comment"></span>
                            <span class="menu-text">Web Whatsapp</span>
                        </router-link>
                    </li>
                    <li class="menu-title">
                        <span>Data</span>
                    </li>
                    <li>
                        <router-link to="/cs-page/order">
                            <span class="nav-icon fas fa-cart-shopping"></span>
                            <span class="menu-text">Order</span>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/cs-page/kategori">
                            <span class="nav-icon fas fa-list"></span>
                            <span class="menu-text">Kategori</span>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/cs-page/autotext">
                            <span class="nav-icon fas fa-book"></span>
                            <span class="menu-text">Autotext</span>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/cs-page/toko">
                            <span class="nav-icon fas fa-location-dot"></span>
                            <span class="menu-text">Alamat Toko</span>
                        </router-link>
                    </li>
                </div>

                <div v-if="level == 'admin'">
                    <li class="menu-title">
                        <span> Admin Menu </span>
                    </li>
                    <li>
                        <router-link to="/admin/laporan">
                            <span class="nav-icon fas fa-book"></span>
                            <span class="menu-text">Laporan</span>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/admin/statistics">
                            <span class="nav-icon fas fa-desktop"></span>
                            <span class="menu-text">Monitoring</span>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/admin/device-diskonek">
                            <span class="nav-icon fas fa-mobile"></span>
                            <span class="menu-text">Device diskonek</span>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/admin/pengaturan">
                            <span class="nav-icon fas fa-cog"></span>
                            <span class="menu-text">Pengaturan</span>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/admin/paket">
                            <span class="nav-icon fas fa-wallet"></span>
                            <span class="menu-text">List Paket</span>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/admin/users">
                            <span class="nav-icon fas fa-users-cog"></span>
                            <span class="menu-text">List User</span>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/admin/message-template">
                            <span class="nav-icon fas fa-envelope"></span>
                            <span class="menu-text">Template Pesan</span>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/admin/messages">
                            <span class="nav-icon fas fa-mail-bulk"></span>
                            <span class="menu-text">Message User</span>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/admin/withdraw">
                            <span class="nav-icon fas fa-money-bill"></span>
                            <span class="menu-text">Withdraw</span>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/admin/coupon">
                            <span class="nav-icon fas fa-percentage"></span>
                            <span class="menu-text">Coupon</span>
                        </router-link>
                    </li>
                </div>
            </ul>
        </div>
    </aside>
</template>

<script>
export default {
    components: {},
    data() {
        return {
            asideShow: true,
            level: localStorage.getItem("role")
        }
    },
    mounted() {
        var isMobile = Math.min(window.screen.width, window.screen.height) < 768 || navigator.userAgent.indexOf("Mobi") > -1;
        if (isMobile) {
            document.querySelector(".sidebar").classList.toggle("collapsed")
        }

        this.$root.$on("toggle_sidebar", () => {
            //$('.overlay-dark-sidebar').toggleClass('show');
            document.querySelector(".sidebar").classList.toggle("collapsed")
            document.querySelector(".contents").classList.toggle("expanded")
        })

        // window.addEventListener('resize', () => {
        //     if (isMobile) {
        //         document.querySelector(".sidebar").classList.toggle("collapsed")
        //     }
        // })

    },
    methods: {

    }
}
</script>

<style scoped>
.router-link-active * {
    color: #5f63f2 !important;
}
</style>
