import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import auth from './modules/auth';
import user from './modules/user';
import contact from './modules/contact';
import device from './modules/device';
import cdn from './modules/cdn';
import outgoing from './modules/outgoing';
import autoreply from './modules/autoreply';
import order_online from './modules/order_online';
import customer_service from './modules/cs';
import chat_widget from './modules/chat_widget';
import campaign from './modules/campaign';
import link_rotator from './modules/link_rotator';
import packages from './modules/package';
import withdraw from './modules/withdraw';
import incoming from './modules/incoming';
import admin from './modules/admin';
import agency from './modules/agency';
import history from './modules/history';
import autotext from './modules/autotext';
import category from './modules/category';
import toko from './modules/toko';
import order from './modules/order';
import cs_toko from './modules/cs_toko';
import chat_gpt from './modules/chatgpt';

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    cdn,
    auth,
    user,
    contact,
    device,
    outgoing,
    autoreply,
    order_online,
    customer_service,
    chat_widget,
    campaign,
    link_rotator,
    packages,
    withdraw,
    incoming,
    admin,
    agency,
    history,
    autotext,
    category,
    toko,
    order,
    cs_toko,
    chat_gpt
  },
  plugins: [createPersistedState()]
});
